<div (click)="onBackClick()" class="back-icon">
  <app-back-icon></app-back-icon>
</div>

<span class="overlay"></span>
<div class="container">
  <div class="side">
    <h1 class="skill-header">ABOUT</h1>
    <app-dp-logo height="18rem" width="18rem" class="dp-logo"></app-dp-logo>
    <div class="text-container">
      <p style="text-align:justify">
        Surya Raj is a highly motivated and goal-oriented individual seeking to further his knowledge and experience in any way, shape or form possible. 
        He is an avid learner, quick on his feet, and calm under pressure. 
        He is exceedingly patient and inventive and looks for opportunities available to enhance his Character.
      </p>
      <br>
      <p style="text-align:justify;">
        Surya Raj prefers being the one who takes control of situations. 
        He is likely ready to take the lead and be assertive when voicing his opinions. 
        He prefers sticking to what he is familiar with times, and also willing to try something new. 
        He is happy to take a conventional approach to his work, but will likely to change his approach on occasion.
      </p>
      <br>
      <p style="text-align:justify;">
        Surya Raj is energized by working in an environment that encourages positively contributing to society. 
        Work with a humanitarian focus is his priority. 
        He feels engaged and satisfied in the workplace if it focuses on social wellbeing.
      </p>
    </div>
  </div>
</div>
