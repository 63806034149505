<div class="portfolio-bg">
  <div class="portfolio-container">
    <div class="portfolio-header" #portfolioHeader>
      <app-dp-logo
        [height]="dpLogoStyle.height"
        [width]="dpLogoStyle.width"
        [borderWidth]="dpLogoStyle.borderWidth">
      </app-dp-logo>
      <app-typing
        time="2000"
        delay="1500"
        [text]="animatedTypingText">
      </app-typing>
    </div>
    <div class="portfolio-area" #portfolioArea>
      <div class="back-icon" (click)="onBackBtnClick()">
        <app-back-icon></app-back-icon>
      </div>
      <div class="portfolio-selection" #portfolioSelectionArea [ngClass]="{'portfolio-selection__fixed': setFixedPostion }">
        <h1>PORTFOLIO</h1>
        <app-key-skill-btns></app-key-skill-btns>
      </div>
      <app-projects></app-projects>
    </div>
  </div>
</div>
