<div class="container">
  <div class="card">
    <div class="strip-block">
      <div class="profile-logo">
        <img src="assets/img/surya-raj.png" alt="">
      </div>
      <div class="strip"></div>
      <div class="short-details">
        <h4 class="tagLine">
          Designer | Developer | Mentor
        </h4>
        <blockquote class="quote">
          "I enjoy turning complex problems into simple."
        </blockquote>
      </div>
    </div>

    <div class="details">
      <div class="nameBlock">
        <h1 class="round-shape name">
          Surya Raj
        </h1>
        <div class="divider">
          <div class="stick stick1"></div>
          <div class="stick stick2"></div>
        </div>
      </div>
      <p class="bio">
        I am passionate about building apps that improve the lives of those around me.
        <br> I like to do the things from scratch and enjoy bringing ideas to real-world applications.
      </p>
      <div (mouseover)="startTadaAnimation = false" [ngClass]="{'tada-animation':startTadaAnimation}">
        <a class="round-shape projects-link" (click)="onExploreMeClick()">
          <span>
            Explore Me
          </span>
        </a>
      </div>
    </div>
    <div class="divider">
      <div class="stick stick1"></div>
      <div class="stick stick2"></div>
    </div>

    <div class="social">
      <div class="social-icon">
        <a href="https://www.linkedin.com/in/suryakantamangaraj/" target="_blank">
          <img src="assets/assert/contactIcon/linkedin.svg" alt="linkedin icon">
        </a>
      </div>
      <div class="social-icon">
        <a href="https://www.instagram.com/suryaraj.ig/" target="_blank">
          <img src="assets/assert/contactIcon/instagram.svg" alt="instagram icon">
        </a>
      </div>
      <div class="social-icon">
        <a href="https://github.com/suryakantamangaraj" target="_blank">
          <img src="assets/assert/contactIcon/github.svg" alt="github icon">
        </a>
      </div>
      <div class="social-icon">
        <a href="https://www.facebook.com/suryaraj.fb" target="_blank">
          <img src="assets/assert/contactIcon/facebook.svg" alt="facebook icon">
        </a>
      </div>
      <div class="social-icon">
        <a href="https://twitter.com/_suryaraj_" target="_blank">
          <img src="assets/assert/contactIcon/twitter.svg" alt="twitter icon">
        </a>
      </div>
      <div class="social-icon">
        <a href="mailto:surya.socialnetworking@gmail.com">
          <img src="assets/assert/contactIcon/email.svg" alt="email icon">
        </a>
      </div>
    </div>

  </div>
</div>
