<div class="card-outer" (click)="onCardClick(project)">
  <div class="card card__front" [ngClass]="{'card__featured':project.isFeatured}">
    <div class="card-container">
      <h4 class="card-header">{{project.name | noEmoji}}</h4>
      <p class="card-short-details">
        {{project.description}}
      </p>
    </div>
  </div>
  <div class="card card__back">
    <div class="card-container">
      <h4 class="card-header">{{project.name}}</h4>
      <div class="card-short-details">
        <div class="tech-stuff-details">
          <span>Technology: </span>
          <p>{{project.techStuff.join(' , ') | maxLength:100:true}}</p>
        </div>
        <button class="full-width-btn">More Details</button>
      </div>
    </div>
  </div>
</div>
