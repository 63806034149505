import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-icon',
  templateUrl: './back-icon.component.html',
  styleUrls: ['./back-icon.component.scss']
})
export class BackIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
